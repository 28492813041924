import * as React from "react";

const UserAgreementTR = () => {
  return <>
    <ul className={"ordered-text-items"}>
      <h2>Kullanıcı Sözleşmesi</h2>
      <li>
        1. Paramla Finansal Danışmanlık A.Ş. (‘Paramla’), web sitesi ve mobil uygulamaları üzerinden sunulan hizmetleri değiştirme veya sonlandırma hakkına sahiptir.
      </li>
      <li>
        2. Piyasa verileri, Forinvest Yazılım ve Teknoloji Hizmetleri A.Ş. (Foreks), CoinMarketCap ve <a
          href={"https://polygon.io"}
                                                                                        target={"_blank"}>Polygon.io</a> Inc.
        tarafından sağlanmaktadır;
        gösterilen fiyatlar ‘son işlem’ fiyatlarıdır; BIST ve Eurobond verileri 15 (onbeş) dakika gecikmelidir.
      </li>
      <li>
        3. Finansal varlıkların gösterilen güncel değerleri (‘son işlem’ fiyatları üzerinden), değişimleri veya kar/zarar hesaplamaları, yatırım danışmanlığı hizmeti sağlanması
        olarak anlaşılamaz veya yorumlanamaz; bu hizmet yalnızca müşteri ile kanunen yetkili kuruluşlar arasında imzalanacak yatırım danışmanlığı hizmet sözleşmesi çerçevesinde
        sağlanabilir.
      </li>
      <li>
        4. Finansal varlıkların gösterilen güncel değerleri, değişimleri veya kar/zarar hesaplamalarına dayanan yatırım kararları, beklenen sonuçları sağlamayabilir.
      </li>
      <li>
        5. Paramla, web sitesi veya mobil uygulamaları için kullanılan kaynaklarca sağlanan hatalı veya noksan veriler nedeniyle veya web sitesi veya mobil uygulamalarında sağlanan
        bilgilerin kullanılması sonucunda oluşabilecek doğrudan veya dolaylı zarar, kâr kaybı, manevi zarar veya üçüncü kişilerin uğrayabileceği herhangi bir zarardan hiçbir
        şekilde sorumlu tutulamaz.
      </li>
      <li>
        6. Paramla, web sitesi ve mobil uygulamalarındaki marka, logo, tasarım, grafik, yazılım vb. eserlerin mülkiyet ve mülkiyetten doğan telif haklarının yegane sahibidir.
      </li>
      <li>
        7. Paramla, web sitesi veya mobil uygulamalarında reklamını gösterdiği üçüncü şahısların sağladıkları içerik veya eylemlerinden hiçbir şekilde sorumlu tutulamaz.
      </li>
    </ul>
    <ul className={"ordered-text-items"}>
      <h2>Gizlilik Politikası</h2>
      <li>
        1. Kişiyi Belirleyici Bilgi (‘KBB’), kişinin kendisini tanıtmak için sunduğu ad, soyad, e-posta adresi vb. kimliğine ilişkin şahsi bilgidir. Paramla, herhangi bir KBB
        sormaz ve saklamaz.
      </li>
      <li>
        2. Paramla, veri tabanında bulunan diğer bilgileri, istatistiksel amaçla kullanma hakkına sahiptir.
      </li>
    </ul>
  </>
}

export default UserAgreementTR;
