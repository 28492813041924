import * as React from "react";
import Layout from "../../components/LayoutPlain";
import UserAgreementTR from "content/UserAgreementTR";

const PrivacyPolicyTRPage = () => {
  return <Layout>
    <UserAgreementTR />
  </Layout>
}

export default PrivacyPolicyTRPage;