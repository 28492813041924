import * as React from "react";
import "fontsource-roboto"
import "./style.scss";

const LayoutPlain = ({children}) => {
  return <div className="layout-plain">
    {children}
  </div>
}

export default LayoutPlain;